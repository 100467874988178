<template>
    <router-view></router-view>
</template>

<script>

export default {
  name: 'Admin',
  data() {
      return {
      }
  }
}
</script>

